import axios from 'axios';
import { API_URL } from 'utils/api';
export var getVehiclesAPI = function getVehiclesAPI() {
    var url = "".concat(API_URL, "/semiCar/query");
    var payload = {
        fetchOptions: {
            carPrices: {
                where: {
                    price: {
                        $gte: 100000
                    }
                }
            },
            semiCarImages: {
                where: {
                    type: 'ext'
                }
            },
            promoRelations: {
                promos: true
            },
            options: {
                distinct: true
            },
            pagination: {
                perPage: 14,
                page: 0
            },
            agencies: {
                addresses: true
            }
        },
        lastUpdated: 'DESC'
    };
    return axios.post(url, payload).then(function (resp) {
        return resp.data;
    });
};

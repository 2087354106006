export var calculateAmountToFinance = function calculateAmountToFinance(carPrice, deposit) {
    return carPrice - deposit;
};
export var calculateMonthlyPayments = function calculateMonthlyPayments(carPrice, amount) {
    var numberOfMonths = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 72;
    var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "new";
    var interestRate = 14.99;
    if (type === "new" && amount / carPrice >= 0.4) {
        interestRate = 13.49;
    }
    interestRate /= 100;
    interestRate /= 12;
    var rate = Math.pow(1 + interestRate, numberOfMonths);
    return Math.round(amount * interestRate * rate / (rate - 1));
};
export var getMaxTermSemiCarFinancing = function getMaxTermSemiCarFinancing(carYear) {
    var year = new Date().getFullYear();
    var yearDiff = year - carYear;
    if (yearDiff <= 2) {
        return 60;
    }
    else if (yearDiff <= 4) {
        return 48;
    }
    return 24;
};
